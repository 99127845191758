export const coins = [
    {
        "id": 1,
        "coin_symbol": "HOO",
        "coin_description": "Revolutinary deflationary token that aims to solve wealth inequality across the balkan region eflationary token that aims to solve wealth inequality across the balkan region",
        "telegram_link": "This is telegram link",
        "website": "website",
        "contract_address": "Oracuru",
        "chain": "chian",
        "logo":"https://avatars.dicebear.com/api/male/asdfasdfasdfasf.svg",
        "launch_date": new Date(),
        "coin_name": "OWL PROTOCOL",
        "upvotes": 8,
        "upvoted_users": [1,2],
        audit: true,
        doxxed: false,
        "date_added": "2021-01-03"
    },
    {
        "id": 13,
        "coin_symbol": "COO",
        "coin_description": "Revolutinary deflationary token that aims to solve wealth inequality across the balkan region eflationary token that aims to solve wealth inequality across the balkan region",
        "telegram_link": "This is telegram link",
        "website": "website",
        "contract_address": "Oracuru",
        "chain": "chian",
        "logo":"https://avatars.dicebear.com/api/male/asdfasdfasdfasf.svg",
        "launch_date": new Date(),
        "coin_name": "OWL PROTOCOL",
        "upvotes": 8,
        "upvoted_users": [1,2],
        audit: true,
        doxxed: false,
        "date_added": "2021-01-03"
    },
    {
        "id": 18,
        "coin_symbol": "BOO",
        "coin_description": "Revolutinary deflationary token that aims to solve wealth inequality across the balkan region eflationary token that aims to solve wealth inequality across the balkan region",
        "telegram_link": "This is telegram link",
        "website": "website",
        "contract_address": "Oracuru",
        "chain": "chian",
        "logo":"https://avatars.dicebear.com/api/male/asdfasdfasdfasf.svg",
        "launch_date": new Date(),
        "coin_name": "OWL PROTOCOL",
        "upvotes": 8,
        "upvoted_users": [1,2],
        audit: true,
        doxxed: false,
        "date_added": "2021-01-03"
    },
    {
        "id": 20,
        "coin_symbol": "Bezos",
        "coin_description": "Revolutinary deflationary token that aims to solve wealth inequality across the balkan region eflationary token that aims to solve wealth inequality across the balkan region",
        "telegram_link": "This is telegram link",
        "website": "website",
        "contract_address": "Oracuru",
        "chain": "chian",
        "logo":"https://avatars.dicebear.com/api/male/asdfasdfasdfasf.svg",
        "launch_date": new Date(),
        "coin_name": "Bezos",
        "upvotes": 7,
        "upvoted_users": [1],
        audit: true,
        doxxed: false,
        "date_added": "2021-01-03"
    },
    {
        "id": 23,
        "coin_symbol": "LOO",
        "coin_description": "Revolutinary deflationary token that aims to solve wealth inequality across the balkan region eflationary token that aims to solve wealth inequality across the balkan region",
        "telegram_link": "This is telegram link",
        "website": "website",
        "contract_address": "Oracuru",
        "chain": "chian",
        "logo":"https://avatars.dicebear.com/api/male/asdfasdfasdfasf.svg",
        "launch_date": new Date(),
        "coin_name": "Bezos",
        "upvotes": 7,
        "upvoted_users": [1],
        audit: true,
        doxxed: false,
        "date_added": "2021-01-03"
    },
    {
        "id": 52,
        "coin_symbol": "ATALANTA",
        "coin_description": "Revolutinary deflationary token that aims to solve wealth inequality across the balkan region eflationary token that aims to solve wealth inequality across the balkan region",
        "telegram_link": "This is telegram link",
        "website": "website",
        "contract_address": "Oracuru",
        "chain": "chian",
        "logo":"https://avatars.dicebear.com/api/male/asdfasdfasdfasf.svg",
        "launch_date": new Date(),
        "coin_name": "ZOO",
        "upvotes": 7,
        "upvoted_users": [1],
        audit: true,
        doxxed: false,
        "date_added": "2021-01-03"
    },
    {
        "id": 73,
        "coin_symbol": "MY COIN",
        "coin_description": "Revolutinary deflationary token that aims to solve wealth inequality across the balkan region eflationary token that aims to solve wealth inequality across the balkan region",
        "telegram_link": "This is telegram link",
        "website": "website",
        "contract_address": "Oracuru",
        "chain": "chian",
        "logo":"https://avatars.dicebear.com/api/male/asdfasdfasdfasf.svg",
        "launch_date": new Date(),
        "coin_name": "Coin name",
        "upvotes": 3,
        "upvoted_users": [1],
        audit: false,
        doxxed: false,
        "date_added": "2021-01-05"
    },
    {
        "id": 84,
        "coin_symbol": "MY COIN",
        "coin_description": "Revolutinary deflationary token that aims to solve wealth inequality across the balkan region eflationary token that aims to solve wealth inequality across the balkan region",
        "telegram_link": "This is telegram link",
        "website": "website",
        "contract_address": "Oracuru",
        "chain": "chian",
        "logo":"https://avatars.dicebear.com/api/male/asdfasdfasdfasf.svg",
        "launch_date": new Date(),
        "coin_name": "Coin name",
        "upvotes": 5,
        "upvoted_users": [1],
        audit: true,
        doxxed: true,
        "date_added": "2021-01-05"
    }
]