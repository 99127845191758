import styled  from "styled-components"

export const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.upvoted ? "#262932" : "white"};
  color: ${props => props.upvoted ? "white" : "#262932"};

  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border: 2px solid black;
  border-radius: 3px;
`;
