import styled  from "styled-components"

export const Circle = styled.div`
 height: 100px;
 width: 100px;
 border-style: solid;
 border-width: 5px;
 border-radius: 50%;
 border-color: black;
`


export const GreenCircle = styled(Circle)`
background-color: green;
border-color: green;
height: 20px;
width: 20px;
`;


export const RedCircle = styled(Circle)`
background-color: red;
border-color: red;
height: 20px;
width: 20px;
`;
