import React from 'react';
import firebase from "firebase/app";
import { Button } from "../components/Buttons/Buttons"
import { googleAuthProvider as provider, auth } from "../firebaseConfig"
import { BlackCard } from "../components/Cards/Card"
import GIFT_ICON from "./img/gift.png"
//use min-h-screen not h-screen

const Promote = () => {
    return(
        <div class="flex justify-center items-center ">
           <BlackCard>
                <div class="flex flex-col justify-center items-center">
                    <img src={GIFT_ICON} height="400px" width="400px"/>
                    <p class="text-xl poppy text-black">Send an email to
                         <p class="text-md text-yellow-500 poppy">promotions@coinbuzz.app</p> 
                          to request a coin promotion.</p>

                    <p class="text-xl poppy text-black">Promoted coins show up as buzzin' coins on the main homepage</p>
                </div>
           </BlackCard>
            
        </div>
    )
}


export default Promote;





