import styled  from "styled-components"

export const Card = styled.button`
  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border: 1px solid black;
  border-radius: 5px;
`;

export const BlackCard = styled(Card)`
 background-color: white;
 font-color: black;
`;
